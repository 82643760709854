@import 'assets/styles/forms.scss';

html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

body {
  background-color: #215873 !important;
}

* {
  box-sizing: border-box;
}

.content {
  line-height: 1.5;
  padding: 0 0 20px !important;
  margin: 20px 40px;
}

.content-block {
  width: 100%;
  min-width: 600px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: white;
  border-radius: 10px;
}

.responsive-paddings {
  padding: 15px;
}

.no-margin-p {
  p {
    margin: 0;
  }
}

.page-title {
  margin: 0;
  padding: 0;
  font-size: 22px !important;
  font-weight: 300;
  margin-bottom: 20px;
}

.dx-fieldset-header {
  border-bottom: 1px solid #eee;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.flexContainer {
  display: flex;
}

.flexContainerItem {
  flex: 1;
  word-break: break-all;
}

.responsive-box {
  display: flex;
  flex-wrap: wrap;
}

.responsive-box-item {
  flex: 50%;
}

.bss-hr {
  border: none;
  border-top: 1px dashed #ccc;
}

::ng-deep .multiview-reordering .dx-list-item {
  // Versuch zum fix eines Problems mit verschwindenden Textfarben
  color: rgba(0, 0, 0, 0.87) !important;
}
