.dx-fieldset {
	margin: 0 0 15px 0 !important;
}

.dx-field {
    flex-direction: column;
  
    .dx-field-label {
      width: unset;
      float: unset;
      align-self: unset !important;
    }
    
    .dx-field-value {
      width: unset !important;
    }
}

.dx-selectbox-popup-wrapper .dx-list-item {
    margin: 0 !important;

    .dx-list-item-content {
        font-size: 14px;
    }
}

.fieldbox {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
  
	> * {
	  width: 45%;
	  margin-bottom: 20px;
	}
}

.bss-data-grid {
	.dx-checkbox-checked {
		.dx-checkbox-icon {
			background: #00314C;
		}
	}
}

.swal2-title {
	font-size: 2rem !important;
	font-weight: bold !important;
}

.swal2-container {
	z-index: 10000 !important;

	.swal2-header {
		.swal2-title {
			font-size: 1.3rem !important;
			font-weight: bold;
		}
	}
	.swal2-actions {
		.swal2-styled {
			background-color: rgb(255, 255, 255) !important;
			color: #808080 !important;
			box-shadow: 0 0 4px #00000054 !important;
			width: auto;
		}
	}
}

.block-button {
	display: block;
	width: 100%;
	max-width: 200px;
}

.full-width-button {
	display: block; 
	padding: 14px;
	width:100%;
}

.bss-button {
	border: none;
	border-radius: 5px;
	padding: 10px;
  	font-size: 14px;
  	cursor: pointer;
	text-align: center;
}

.primary-button {
	background: #00314C;
	color: white;
}

.default-button {
	background: #E3E4E5;
	color: black;
}

.danger-button {
	background: #DC3545;
	color: white;
}

@media only screen and (max-width: 992px) {
	.fieldbox {
	  flex-direction: column;
  
	  > * {
		width: 100%;
	  }
	}
}
